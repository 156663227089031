import React from 'react';
import './about.scss';
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar } from "@fortawesome/free-solid-svg-icons";

function About() {
    return (
        <div className="about-container">
            <Helmet>
                <title>About Goldwrench Automotive - Professional Auto Services</title>
                <meta name="description" content="Learn about Goldwrench Automotive, your trusted family-owned auto service provider since 2007. Our ASE-certified technicians are committed to excellent service and customer satisfaction." />
                <meta name="keywords" content="Goldwrench Automotive, auto repair, ASE-certified technicians, NAPA AutoCare Center, auto services, family-owned business, car maintenance" />
            </Helmet>

            {/* Vision Card */}
            <section className="vision">
                <h2>Vision</h2>
                <div className="vision-content">
                    <h3>#1 IN THE UNIVERSE</h3>
                    <p>If There’s Cars on Mars, We’ll Be There to Fix Them</p>
                </div>
            </section>
            
            {/* Mission & Values Card */}
            <section className="mission-values">
                <h2>Mission & Values</h2>
                <div className="mission">
                    <h3>Mission</h3>
                    <p>Set the Standard…The GOLD Standard</p>
                    <p>Improve Each Day</p>
                    <p>Have Some Fun</p>
                </div>
                <div className="values">
                    <h3>Values</h3>
                    <p>Create Raving Fans</p>
                    <p>Be Honest, Show Respect, and Have Integrity</p>
                    <p>Monitor Results, Not Activities</p>
                    <p>Mistakes are a Wise Person’s Education</p>
                    <p>Challenge Ourselves, Challenge Each Other, Break Boundaries</p>
                    <p>Give Loyalty – Get Loyalty</p>
                    <p>Communication, Communication, Communication</p>
                    <p>Attitude Is a Matter of Choice - Make the Right Choice</p>
                    <p>Contribute to the Team</p>
                    <p>Enjoy the Journey - Do It With Pride!</p>
                </div>
            </section>
            
            {/* About Us Card */}
            <section className="about-us">
                <h2><FontAwesomeIcon icon={faCar} /> About Goldwrench Automotive</h2>
                <p>GOLDWrench Automotive is a family owned and operated small business started in March of 2007. Our Mission is to Set the Standard, Improve Each Day and Have Some Fun!</p>
                <p>Our team consists of ASE-certified technicians, led by an ASE-Master technician. We work on both light diesel and passenger vehicles, using high-quality parts to provide excellent service and Nationwide Warranty through our partnership as a Napa Autocare Center. We offer an array of services to fit the needs of the families we serve. Tires, Brakes, Alignments and a number of other services to keep you moving forward.</p>
                <p>We strive to Create Raving Fans with our service, Be Honest, Show Respect and Have Integrity in all that we do. Our family grows every day with our team members, our customers and our community. Give Loyalty Get Loyalty is a cherished value as we Enjoy the Journey and Do it With Pride!</p>
                <p>Thank you for choosing to work with us and it will be our pleasure to serve you!</p>
                <p><strong>The GOLDWrench Family</strong></p>
                <p><strong>P.S. - COME JOIN OUR TEAM!</strong> WE’RE GROWING AND ALWAYS LOOKING FOR TOP TALENT! Technicians, Service Writers, Marketers, Many Positions are Available - We look for Attitude above all - Attitude is a Matter of Choice - Make the Right Choice!</p>
            </section>
        </div>
    );
}

export default About;
